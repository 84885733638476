import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

interface S3UrlData {
  uploadUrl: string;
  publicUrl: string;
}

@Injectable()
export class AssetsService {

  constructor(private http: HttpClient) {}

  authorizeAsset(resource: string, name: string): Observable<S3UrlData> {
    const params = new HttpParams()
      .set('resource', resource)
      .set('resource_name', name);

    return this.http.get<any>(`${environment.apiUrl}/assets/upload_request`, { params }).pipe(
      map(data => data.url_data),
      map(data => ({ uploadUrl: data.upload_url, publicUrl: data.public_url }))
    );
  }

  destroy(id: number, resource_type: string) {
    const params = new HttpParams()
      .set('resource_type', resource_type);
    return this.http.delete<any>(`${environment.apiUrl}/assets/${id}`, { params });
  }
}
