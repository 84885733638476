export class StoreSyncItem {
  id: number;
  storeProductId: number;
  storeCategoryId: number;
  productVariantId: number;
  sku: string;
  name: string;
  sizeName: string;
  category: string;
  stock: number;
  brand: string;
  status: string;

  constructor(json) {
    this.id = json.id;
    this.storeProductId = json.store_product_id;
    this.sku = json.sku;
    this.name = json.name;
    this.sizeName = json.size_name;
    this.status = json.status;
    this.stock = json.stock;
    this.category = json.category;
    this.brand = json.brand;
    this.storeCategoryId = json.store_category_id;
    this.productVariantId = json.product_variant_id;
  }

  get match() {
    return this.status === 'to_confirm';
  }

  get synced() {
    return this.status === 'auto_matched';
  }

  get unmatch() {
    return this.status === 'unmatched';
  }
}

export class StoreSync {
  id: number;
  items: StoreSyncItem[] = [];

  constructor(json) {
    this.id = json.id;
    this.items = (json.store_sync_items || []).map(it => new StoreSyncItem(it));
  }

  get autoMatched() {
    return this.items.filter(it => it.synced);
  }

  get matches() {
    return this.items.filter(it => it.match);
  }

  get unmatches() {
    return this.items.filter(it => it.unmatch);
  }
}

export class SyncError {
  private data: any;

  constructor(json) { this.data = json; }

  get errors(): any[] {
    if (this.data && this.data.errors) {
      return this.data.errors;
    }
    return [];
  }
}
