import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentStoreService {

  public storeId$: ReplaySubject<number> = new ReplaySubject(1);

  constructor() { }
}
