import { Injectable } from '@angular/core';

import { CrudService } from './crud.service';
import { LayoutPosition } from '../models';

@Injectable()
export class LayoutPositionService extends CrudService<LayoutPosition> {

  createResource(params: any): LayoutPosition {
    return new LayoutPosition(params);
  }

  resourceName({plural}: {plural?: boolean} = {}): string {
    return plural ? 'layout_positions' : 'layout_position';
  }
}
