import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError,  of as observableOf, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { StoreSync, SyncError, StoreSyncItem } from '../models';

@Injectable()
export class StoreSyncService {

  constructor(private http: HttpClient) {}

  resourcePath(storeId: number) {
    return `${environment.apiUrl}/stores/${storeId}/store_syncs`;
  }

  get(id: number, storeId: number): Observable<StoreSync> {
    return this.http.get<any>(`${this.resourcePath(storeId)}/${id}`).pipe(
      map(data => new StoreSync(data['store_sync']))
    );
  }

  create(storeId: number, file?: File): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    return this.http.post<any>(this.resourcePath(storeId), formData).pipe(
      map(response => response),
      catchError(response => {
        if (response.error && response.error.errors) {
          return observableThrowError(new SyncError(response.error));
        }
        return observableThrowError(new SyncError(response));
      })
    );
  }

  process(id: number, storeId: number, item: StoreSyncItem): Observable<any> {
    const params: any = {
      store_sync_item_id: item.id,
      store_category_id: item.storeCategoryId,
      product_variant_id: item.productVariantId
    };

    if (item.storeProductId) {
      params.store_product_id = item.storeProductId;
    }

    return this.http.post<any>(`${this.resourcePath(storeId)}/${id}/sync_item`, params).pipe(
      map(data => new StoreSync(data['store_sync']))
    );
  }

  finish(id: number, storeId: number): Observable<boolean> {
    return this.http.put<any>(`${this.resourcePath(storeId)}/${id}/finish`, {}).pipe(
      map(data => true),
      catchError(error => observableOf(false))
    );
  }
}
