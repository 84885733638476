import { Routes } from '@angular/router';
import { SessionGuard } from '@yellowspot/jwt-auth-service';

import { ErrorComponent } from './error/error.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';

export const ROUTES: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  {
    path: 'app',
    canActivate: [SessionGuard],
    canActivateChild: [SessionGuard],
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**',    component: ErrorComponent }
];
