import { Injectable, ResolvedReflectiveFactory } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Pagination } from '../../core/models';
import { environment } from '../../../environments/environment';

export interface ReourcesResult<T> {
  resources: T[];
  pagination: Pagination;
}

interface IndexParams {
  page?: number;
  pageSize?: number;
  sort?: any;
  filters?: any;
}

@Injectable()
export abstract class CrudService<T> {

  constructor(protected http: HttpClient) { }

  abstract createResource(params: any): T;

  /*
  * @return IE category/categories
  * give a default value at implementation -> {plural}: {plural?: boolean} = {}
  */
  abstract resourceName({ plural }?: { plural?: boolean }): string;

  resourcePath({ parentId }: { parentId?: number } = {}): string {
    return this.resourceName({ plural: true });
  }

  all({ page, pageSize, sort, filters }: IndexParams = {}, pathOptions?: { parentId?: number, overrideResourceName?: string }): Observable<ReourcesResult<T>> {

    let params = new HttpParams()
      .set('page', page ? page.toString() : '1')
      .set('per_page', pageSize ? pageSize.toString() : '10')
      .set('sort_by', sort ? sort.prop : 'id')
      .set('sort_direction', sort ? sort.direction : 'desc');

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        params = params.set(key, filters[key]);
      }
    }

    return this.http.get<any>(`${environment.apiUrl}/${this.resourcePath(pathOptions)}`, { params })
      .pipe(
        map(data => {
          const pagination = new Pagination(data.meta);
          if (pageSize) {
            pagination.pageSize = pageSize;
          }
          return {
            pagination,
            resources: data[pathOptions && pathOptions.overrideResourceName ? pathOptions.overrideResourceName : this.resourceName({ plural: true })].map(c => this.createResource(c))
          } as ReourcesResult<T>;
        })
      );
  }

  private create(resource: any, pathOptions?: { parentId?: number, overrideResourceName?: string }): Observable<T> {
    const params = {};
    params[pathOptions && pathOptions.overrideResourceName ? pathOptions.overrideResourceName : this.resourceName()] = resource;
    console.log(resource, params)
    return this.http.post<any>(`${environment.apiUrl}/${this.resourcePath(pathOptions)}`, params).pipe(
      map(data => this.createResource( pathOptions && pathOptions.overrideResourceName ? data[pathOptions.overrideResourceName] : data[this.resourceName()]))
    );
  }

  private update(resource: any, pathOptions?: { parentId?: number, overrideResourceName?: string }): Observable<T> {
    const params = {};
    params[pathOptions && pathOptions.overrideResourceName ? pathOptions.overrideResourceName : this.resourceName()] = resource;
    return this.http.put<any>(`${environment.apiUrl}/${this.resourcePath(pathOptions)}/${resource.id}`, params)
      .pipe(map(data => this.createResource( pathOptions && pathOptions.overrideResourceName ? data[pathOptions.overrideResourceName]  : data[this.resourceName()])));
  }

  save(resource: any, pathOptions?: { parentId?: number, overrideResourceName?: string }): Observable<T> {
    return resource.id ? this.update(resource, pathOptions) : this.create(resource, pathOptions);
  }

  get(id: number, pathOptions?: { parentId?: number, overrideResourceName?: string }): Observable<T> {
    return this.http.get<any>(`${environment.apiUrl}/${this.resourcePath(pathOptions)}/${id}`).pipe(
      map(data => this.createResource(
        pathOptions && pathOptions.overrideResourceName ? data[pathOptions.overrideResourceName] : data[this.resourceName()])
        )
    );
  }

  destroy(id: number, pathOptions?: { parentId?: number }): Observable<boolean> {
    return this.http.delete<any>(`${environment.apiUrl}/${this.resourcePath(pathOptions)}/${id}`).pipe(
      map(data => true),
      catchError(response => observableOf(false))
    );
  }
}
