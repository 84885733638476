import { Component, ViewEncapsulation, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  styleUrls: [ './error.style.scss' ],
  templateUrl: './error.template.html',
  encapsulation: ViewEncapsulation.None
})
export class ErrorComponent {
  @HostBinding('class') hostClass = 'error-page app';

  router: Router;

  constructor(router: Router) {
    this.router = router;
  }

  searchResult(): void {
    this.router.navigate(['/app', 'dashboard']);
  }
}
