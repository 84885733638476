import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SidebarService {
  private stateChanges: EventEmitter<any> = new EventEmitter();

  onChanges(): Observable<string> {
    return this.stateChanges;
  }

  toggle(state): void {
    this.stateChanges.emit(state);
  }

}
