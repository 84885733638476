import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtAuthService } from '@yellowspot/jwt-auth-service';
import { map, first, tap } from 'rxjs/operators';

import { User } from '../models';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private authSrv: JwtAuthService<User>
  ) { }

  canActivate() {
    const admin$ = this.authSrv.currentUser$.pipe(
      map(user => user && user.admin),
      tap(admin => {
        if (!admin) {
          this.router.navigate(['/forbidden']);
        }
      }),
      first()
    );

    return admin$;
  }
}
