import { Client } from '../../clients/models';

export class User {
  id: number;
  name: string;
  email: string;
  client: Client;

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.email = json.email;
    this.client = new Client(json.client || {});
  }

  get admin(): boolean {
    return !this.client.id;
  }
}
