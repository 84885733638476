import { throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpErrorResponse
} from '@angular/common/http';

@Injectable()
export class RequestErrorsInterceptor implements HttpInterceptor {
  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(data => {
        if (data instanceof HttpErrorResponse) {
          return this.handleError(data);
        }
        return observableThrowError(data);
      })
    );
  }

  private handleError(data) {
    if (/application\/json/.test(data.headers.get('Content-Type'))) {
      return this.parseError(data);
    } else {
      return observableThrowError(data);
    }
  }

  private parseError(data) {
    let error = {};
    try {
      error = JSON.parse(data.error);
    } catch (e) { }

    if (Object.keys(error).length === 0) {
      return observableThrowError(data);
    } else {
      const newError = {...data, error: error};
      return observableThrowError(newError);
    }

  }
}
