import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestErrorsInterceptor } from './interceptors';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { JwtAuthModule, JWT_AUTH_OPTIONS, defaultOptions } from '@yellowspot/jwt-auth-service';
import { AdminGuard } from './guards/admin-guard.service';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { environment } from '../../environments/environment';
import { SidebarService, TagsService, AssetsService, StoreSyncService, LayoutPositionService } from './services';
import { User } from './models';

export const JWT_TOKEN_ID = 'jwt';

export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem(JWT_TOKEN_ID);
    },
    whitelistedDomains: [
      environment.apiUrl.replace(/((http)|(https)):\/\//, '')
    ]
  };
}

export function jwtAuthOptionsFactory() {
  return {
    ...defaultOptions,
    userFromJson: (json) => new User(json.user),
    authUrl: `${environment.apiUrl}/user_token`,
    currentUserUrl: `${environment.apiUrl}/users/current`,
    paramsWrapper: 'auth',
    guardRedirectTo: '/login'
  };
}

@NgModule({
  imports: [
    CommonModule,
    SimpleNotificationsModule.forRoot(),
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      }
    }),
    JwtAuthModule.forRoot({
      jwtAuthOptionsProvider: {
        provide: JWT_AUTH_OPTIONS,
        useFactory: jwtAuthOptionsFactory,
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RequestErrorsInterceptor, multi: true },
    SidebarService,
    TagsService,
    AdminGuard,
    AssetsService,
    StoreSyncService,
    LayoutPositionService
  ],
  declarations: []
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
