export class Asset {
  id: number;
  url: string;

  constructor(json: any) {
    this.id = json.id;
    this.url = json.url;
  }

  toJson() {
    return {id: this.id, url: this.url};
  }
}
