import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';

import { CurrentStoreService } from './core/services/current-store.service';
import { CurrentKioskService } from './core/services/current-kiosk.service';
import { JwtAuthService } from '@yellowspot/jwt-auth-service';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./scss/application.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authSrv: JwtAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private currentStoreSrv: CurrentStoreService,
    private currentKioskSrv: CurrentKioskService
  ) {

  }

  ngOnInit() {
    window['tinyMCE'].overrideDefaults({
      base_url: '/tinymce/',  // Base for assets such as skins, themes and plugins
      suffix: '.min'          // This will make Tiny load minified versions of all its assets
    });

    this.authSrv.fetchCurrentUser();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      map((route) => route.snapshot.params)
    ).subscribe((params) => {
      const storeId = params && params.storeId;
      if (storeId) {
        this.currentStoreSrv.storeId$.next(+storeId);
      } else {
        this.currentStoreSrv.storeId$.next(undefined);
      }

      const kioskId = params && params.kioskId;
      if (kioskId) {
        this.currentKioskSrv.kioskId$.next(+kioskId);
      } else {
        this.currentKioskSrv.kioskId$.next(undefined);
      }
    });
  }

}
