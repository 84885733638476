import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import { JasperoConfirmationsModule } from '@jaspero/ng-confirmations';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ROUTES } from './app.routes';
import { ErrorComponent } from './error/error.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { AppConfig } from './app.config';
import * as  Sentry from '@sentry/angular'

const APP_PROVIDERS = [
  AppConfig
];

@NgModule({
  declarations: [
    AppComponent, ErrorComponent, ForbiddenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules
    }),
    ClipboardModule,
    JasperoConfirmationsModule.forRoot()
  ],
  providers: [
    APP_PROVIDERS,
    {
      provide: Sentry.TraceService,
      deps: [ROUTES],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
