export class Pagination {
  pageSize = 10;
  currentPage = 0;
  nextPage = 0;
  prevPage = 0;
  totalPages = 0;
  totalCount = 0;
  filters?: any;

  constructor(json?) {
    this.filters = {};
    if (!json) {
      return;
    }

    this.currentPage = json.current_page;
    this.nextPage = json.next_page;
    this.prevPage = json.prev_page;
    this.totalPages = json.total_pages;
    this.totalCount = json.total_count;
    this.filters["q"] = json && json.q ? json.q : null;
  }
}
