import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class TagsService {

  constructor(private http: HttpClient) {}

  public tags = (text: string): Observable<string[]> => {
    return this.search(text);
  }

  search(term: string, params?: any): Observable<string[]> {
    let hparams = new HttpParams()
      .set('page', '1')
      .set('per_page', '15')
      .set('q', term);

    if (params) {
      Object.keys(params).forEach(function (item) {
        hparams = hparams.set(item, params[item]);
      });
    }

    return this.http.get<any>(`${environment.apiUrl}/tags`, { params: hparams }).pipe(
      map(data => data.tags.map(t => t.name))
    );
  }
}
